<template>
  <div class="ak-text container-layout-custom" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row">
        <div class="text-h4 col-md-9">Bookmarks</div>
      </div>
      <modules-grid :modules="bookmarks"></modules-grid>
    </div>
  </div>
</template>

<script>
import ModulesGrid from 'components/ModulesGrid'

export default {
  name: 'BookmarksPage',
  components: { ModulesGrid },
  data () {
    return {
      bookmarks: []
    }
  },
  computed: {
    bookmark_uids () {
      return this.$store.getters['bookmarks/data']
    }
  },
  watch: {
    bookmark_uids (newBookmarkUids) {
      this.loadModules()
    }
  },
  methods: {
    loadModules () {
      let queries = []
      let bookmarks = []
      this.$store.getters['bookmarks/data'].forEach(bookmark => {
        queries.push({
          indexName: this.$consts.MODULES_INDEX,
          query: bookmark.uid,
          params: {
            hitsPerPage: 1
          }
        })
      })
      this.$moduleSearch.clearCache()
      this.$moduleSearch.search(queries, (err, content) => {
        if (err) throw err
        content.results.forEach(result => {
          result.hits.forEach(hit => {
            hit.bookmarked = true
            bookmarks.push(hit)
          })
        })
      })
      this.bookmarks = bookmarks
    }
  },
  created () {
    if (this.$store.getters['bookmarks/data'].length === 0) {
      this.$store.dispatch('bookmarks/fetch')
    }
    this.loadModules()
  }
}
</script>

<style lang="stylus" scoped>
</style>
